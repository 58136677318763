import React, { useCallback, useState } from "react";
import Img from "gatsby-image";
import { graphql } from "gatsby";
import { Row, Col } from "antd";

import {
  ButtonPrimary,
  ButtonSecondary,
  Colors,
  ContainerContentFixed,
  ContainerCta,
  InternalLink,
  InternalLinkTypes,
  Layout,
  SEO,
  TitleFloorPlan,
  DisplayInfoBar,
  ExternalLink,
  GalleryViewer,
  GalleryImageDisplay,
  ContainerContent,
  Video,
} from "../components";

export default ({ data }) => {
  const display = data.allDisplaysJson.nodes[0];
  const images = display.images
    .concat(display.facade)
    .concat(display.floorPlan);

  const [selectedImageIndex, setSelectedImageIndex] = useState();

  const onClickImage = useCallback(
    idx => () => {
      setSelectedImageIndex(idx);
    },
    [setSelectedImageIndex]
  );
  const onModalClose = useCallback(() => {
    setSelectedImageIndex(null);
  }, [setSelectedImageIndex]);
  return (
    <Layout>
      <SEO title={`Display Home - ${display.name} ${display.estate}`} />
      {/*<BannerPage*/}
      {/*title={`Display Home - ${display.name}`}*/}
      {/*subTitle={display.estate}*/}
      {/*/>*/}
      <ContainerContent
        style={{
          padding: 0,
          backgroundColor: Colors.LightGray,
          margin: "0 auto",
          textAlign: "center",
        }}
      >
        {display.tourId ? (
          <div
            className="video"
            style={{
              position: "relative",
              paddingBottom: "56.25%" /* 16:9 */,
              paddingTop: 25,
              height: 0,
            }}
          >
            <iframe
              title={display.tourId}
              // width="853"
              // height="480"
              src={`https://www.realtour3d.com.au/3d-model/${display.tourId}/fullscreen/?embedded`}
              frameBorder="0"
              allow="vr"
              allowFullScreen="allowfullscreen"
              style={{
                margin: 0,
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
              }}
            />
          </div>
        ) : (
          display.facade && (
            <Img
              fluid={display.facade.imagePath.childImageSharp.fluid}
              style={{ marginBottom: "8px" }}
            />
          )
        )}
      </ContainerContent>
      <ContainerContentFixed>
        <Row gutter={[8, 8]}>
          <Col md={16} xs={24}>
            <div>
              {display.youtubeLink && <Video url={display.youtubeLink} />}
              <Row gutter={[8, 8]}>
                {images &&
                  images.map((img, idx) => (
                    <Col xl={8} lg={12} md={12} xs={24} key={`img-${idx}`}>
                      {img && img.imagePath && (
                        <GalleryImageDisplay
                          onClick={onClickImage(idx)}
                          imageFluid={img.imagePath.childImageSharp.fluid}
                        />
                      )}
                    </Col>
                  ))}
              </Row>
              <GalleryViewer
                selectedImageIndex={selectedImageIndex}
                images={images}
                onModalClose={onModalClose}
              />
            </div>
          </Col>
          <Col md={8} xs={24}>
            <div style={{ padding: "2rem 2rem" }}>
              <TitleFloorPlan
                style={{ fontSize: "1.5rem" }}
                size={display.floorPlan.size}
                name={display.name}
              />

              <h4
                style={{
                  fontWeight: 400,
                  marginTop: "16px",
                }}
              >
                <ExternalLink url={display.estateLink}>
                  {display.estate}
                </ExternalLink>
              </h4>

              <p style={{ fontSize: "1.1rem" }}>{display.description}</p>
              {!display.floorPlan.hide && (
                <div>
                  <h3 style={{ color: Colors.DarkGreen }}>
                    SUITS {display.floorPlan.site}m WIDE SITE
                  </h3>
                  <h4 style={{ marginBottom: "4px" }}>
                    TOTAL SQUARES: {display.floorPlan.totalSquares}
                  </h4>
                  {/*<h4 style={{ marginBottom: "4px" }}>*/}
                  {/*FITS LOT WIDTH : {floorPlan.info.lotWidth} m*/}
                  {/*</h4>*/}
                  {/*<h4>FITS LOT LENGTH: {floorPlan.info.lotLength} m</h4>*/}
                  <DisplayInfoBar
                    living={display.floorPlan.living}
                    garage={display.floorPlan.carSpace}
                    bed={display.floorPlan.bed}
                    storey={display.floorPlan.storey}
                    bath={display.floorPlan.bath}
                  />
                  {/* {display.floorPlan && display.floorPlan.areas && (
                  <div>
                    <h4 style={{ marginBottom: "0", marginTop: "16px" }}>
                      Areas
                    </h4>
                    <table>
                      <tbody>
                        <tr>
                          <td>
                            {display.floorPlan.storey === 1
                              ? "Residence"
                              : "Ground Floor"}
                          </td>
                          <td>{display.floorPlan.areas.groundFloor} sqm</td>
                        </tr>
                        {display.floorPlan.storey === 2 && (
                          <tr>
                            <td>First Floor</td>
                            <td>{display.floorPlan.areas.firstFloor} sqm</td>
                          </tr>
                        )}
                        {display.floorPlan.areas.garage && (
                          <tr>
                            <td>Garage</td>
                            <td>{display.floorPlan.areas.garage} sqm</td>
                          </tr>
                        )}
                        {display.floorPlan.areas.porch && (
                          <tr>
                            <td>Porch</td>
                            <td>{display.floorPlan.areas.porch} sqm</td>
                          </tr>
                        )}
                        {display.floorPlan.areas.alfresco && (
                          <tr>
                            <td>Alfresco</td>
                            <td>{display.floorPlan.areas.alfresco} sqm</td>
                          </tr>
                        )}
                        {display.floorPlan.areas.total && (
                          <tr>
                            <td>Total Area</td>
                            <td>{display.floorPlan.areas.total} sqm</td>
                          </tr>
                        )}
                        {display.floorPlan.areas.openSpace && (
                          <tr>
                            <td>Private Open Space</td>
                            <td>{display.floorPlan.areas.openSpace} sqm</td>
                          </tr>
                        )}
                        {display.floorPlan.areas.driveway && (
                          <tr>
                            <td>Driveway & Hard Surface</td>
                            <td>{display.floorPlan.areas.driveway} sqm</td>
                          </tr>
                        )}
                        {display.floorPlan.areas.siteCoverage && (
                          <tr>
                            <td>Total Site Coverage</td>
                            <td>{display.floorPlan.areas.siteCoverage} sqm</td>
                          </tr>
                        )}
                        {display.floorPlan.areas.sitePermeability && (
                          <tr>
                            <td>Site Permeability</td>
                            <td>
                              {display.floorPlan.areas.sitePermeability} sqm
                            </td>
                          </tr>
                        )}
                        {display.floorPlan.areas.totalSiteArea && (
                          <tr>
                            <td>Total Site Area</td>
                            <td>{display.floorPlan.areas.totalSiteArea} sqm</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                )} */}
                </div>
              )}
              {display.openingHours && (
                <div>
                  <h4 style={{ color: Colors.DarkGreen, fontSize: "1.2rem" }}>
                    Opening Hours
                  </h4>
                  <table>
                    <tbody>
                      {display.openingHours.map(row => (
                        <tr key={row.day}>
                          <td>{row.day}</td>
                          <td>{row.time}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div style={{ fontSize: "1.1rem" }}>
                    <b>Address: </b>
                    <br />
                    {display.address}
                    <br />
                    <ExternalLink url={display.addressLink}>
                      <small>Open in Google Maps</small>
                    </ExternalLink>
                  </div>
                  <br />
                  <p>Appointments are not required but recommended.</p>
                </div>
              )}
              <ButtonPrimary style={{ margin: "4px 0", width: "100%" }}>
                <InternalLink
                  url={InternalLinkTypes.InspectionByDisplay({
                    display: display.name,
                  })}
                >
                  Request Inspection
                </InternalLink>
              </ButtonPrimary>
              <ButtonSecondary style={{ margin: "4px 0", width: "100%" }}>
                <InternalLink url={InternalLinkTypes.Displays}>
                  See Other Displays
                </InternalLink>
              </ButtonSecondary>
              <InternalLink
                url={InternalLinkTypes.Disclaimer}
                style={{
                  margin: "8px",
                  width: "100%",
                  textAlign: "center",
                  display: "block",
                }}
              >
                See our disclaimer
              </InternalLink>
            </div>
          </Col>
        </Row>
      </ContainerContentFixed>
      <ContainerCta
        title={"Can't find the home design you like?"}
        subTitle={"Don't worry, we can build one as per your needs"}
        secondaryText={"Request Appointment"}
        secondaryLink={InternalLinkTypes.Appointment}
      />
    </Layout>
  );
};

export const query = graphql`
  query($path: String!) {
    allDisplaysJson(filter: { slug: { eq: $path } }) {
      nodes {
        name
        estate
        estateLink
        youtubeLink
        description
        tourId
        address
        addressLink
        openingHours {
          day
          time
        }
        floorPlan {
          size
          storey
          bed
          bath
          living
          carSpace
          site
          totalSquares
          imagePath {
            childImageSharp {
              fluid(maxWidth: 1800) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        facade {
          imagePath {
            childImageSharp {
              fluid(maxWidth: 1800) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        images {
          imagePath {
            childImageSharp {
              fluid(maxWidth: 1200) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`;
